token = ""
onmessage = function (e) {
  console.log(e.data,e.ports);
  port = e.ports[0];
  if (e.data) {
  //  parse(e.data);
 
   sessionStorage.setItem('authorizationTokenFromMobile', JSON.stringify(e.data))

  }
}


function update_lux(wrapper) {
  var luxOut = wrapper ? wrapper : "Script"
  console.log(luxOut, "updateLux");
  token = wrapper

  // Access the component's DOM element
  const appComponentElement = document.getElementById('appRoot');
  // port.postMessage("saved Successfully");
  // Manipulate the component's DOM element or listen to events
  if (appComponentElement) {
    appComponentElement.userToken = token

  }
}

function parse(json) {
 update_lux(json);
}

var port;

pull = function () {
  var luxOut = "Script 123"
  console.log(luxOut)
  document.getElementById("wrapper").innerHTML = luxOut;
  if (port) {
    port.postMessage("ping from WebSite");
    }

}

