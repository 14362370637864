var questionIndex = 0;
var surveyId = "";
var shiftId = ""
var response = {};
var number = "5612732178";
var respMsg = [{ name: 'Visitor', positiveMsg: "We're sorry. You will not be able to a enter Trustbridge facility at this time.", negativeMsg: 'Thank you. You are clear to enter a Trustbridge facility.' },
    { name: 'Vendor', positiveMsg: "We're sorry. You will not be able to enter a Trustbridge facility at this time.", negativeMsg: 'Thank you. You are clear to enter a Trustbridge facility.' },
  { name: 'Employee', positiveMsg: 'Please contact the HR Command Center at (561)-273-2178 prior to reporting to work.', negativeMsg: 'Thank you. You are clear to work today.' }]

var templates = [
    {
        label: "Temperature",
        options: [
            { id: "1", label: "Feeling warm/Feverish", hide: true, responseValue: 1 },
            { id: "2", label: "Feeling okay", hide: true, responseValue: 0 },
            { id: "3", label: "Temperature", noDisplay: true, value: 0, responseValue: 0 }
        ],
        type: "temperature",
        selection: [],
        required: true
    },
    {
        label: "Do you have any of the following symptoms?",
        options: [{ id: "1", label: "Dry cough", responseValue: 1 },
        { id: "2", label: "Shortness of breath or difficulty breathing", responseValue: 1 },
        { id: "3", label: "Sore throat", responseValue: 1 },
        { id: "4", label: "Loss of sense of smell or taste", responseValue: 1 },
        { id: "5", label: "Body aches or headache, unknown cause", responseValue: 1 },
        { id: "6", label: "Chills", responseValue: 1 },
        { id: "7", label: "Repeated shaking with chills", responseValue: 1 },
        { id: "8", label: "Diarrhea", responseValue: 1 },
        { id: "9", label: "None of the above", primarySelection: true, responseValue: 0 }],
        type: "multiSelect",
        selection: [],
        required: true
    },
    // {
    //     label: "Have you traveled to Florida from any location in the past 2 weeks?",
    //     options: [{ id: "1", label: "Yes", responseValue: 1 },
    //     { id: "2", label: "No", responseValue: 0 }
    //     ],
    //     type: "singleSelect",
    //     selection: [],
    //     required: true
    // },
     {
         label: "Have you tested positive for COVID-19 in the last 10 days?",
         options: [{ id: "1", label: "Yes", responseValue: 1 },
         { id: "2", label: "No", responseValue: 0 }
         ],
         type: "singleSelect",
         selection: [],
         required: true
     },
    /*{
        label: "Have you come into close contact with anyone suspected or confirmed to have COVID-19 while not wearing a medical grade facemask or N95 and eye protection? (Note: \"close contact\" is defined as being within 6 feet of distance for a prolonged period of time, OR having contact with secretions such as being coughed on)",
        options: [{ id: "1", label: "Yes", responseValue: 1 },
        { id: "2", label: "No", responseValue: 0 },
        { id: "3", label: "Not sure", responseValue: 1 }
        ],
        type: "singleSelect",
        selection: [],
        required: true
  },*/
    {
      label: "In the last 14 days, have you come into close contact with anyone suspected or confirmed to have COVID-19 while not wearing medical grade facemask or N95 and eye protection ? (Note: \"close contact\" is defined as being within 6 feet of distance for a cumulative total of 15 minutes or more over a 24-hour period)",
      options: [{ id: "1", label: "Yes", responseValue: 1 },
        {
          id: "2",
          label: "No",
          responseValue: 0
        },
      ],
      type: "singleSelect",
      selection: [],
      required: true
    }
];
let g_event = new Event("formActionEvent", { bubbles: true });
let jSForMobile = false;

function init() {
    let initialData=null;
    if (jSForMobile){
        initialData = JSReceiver.getSurveyDetails()
    }else{
        initialData = JSON.stringify({ "surveyId": "jjssjjs-shhshs-shhshs-sshhs", "shiftId": "73120263-b907-4590-8f50-f31d06eb7d0d" }) 
    }
    initialData = JSON.parse(initialData)
    surveyId = initialData["surveyId"]
    shiftId = initialData["shiftId"]
    setContent();
    updateWebEvent('init');
}

function updateOptionsForTemprature() {
    var elem = document.getElementById("tempCheckbox")
    for (let i = 0; i < templates[questionIndex].options.length; i++) {
        if (templates[questionIndex].options[i].hide != undefined) {
            templates[questionIndex].options[i].hide = !elem.checked
        }
    }
    renderOptions();
}

function updateCheckbox() {
    document.getElementById("temprature").value = '';
    if (templates[questionIndex].selection.length > 0 && templates[questionIndex].selection[0].value != undefined) {
        for (let i = 0; i < templates[questionIndex].options.length; i++) {
            if (templates[questionIndex].options[i].label == templates[questionIndex].selection[0].label) {
                templates[questionIndex].options[i].value = 0
                templates[questionIndex].options[i].label = "Temperature"
            }
        }
        templates[questionIndex].selection = [];

        changeButtonStatus()
    }
    var elem = document.getElementById("tempCheckbox")
    elem.checked = !elem.checked
    updateOptionsForTemprature();
}

function isNumberKey(evt) {
    if (document.getElementById("tempCheckbox").checked) {
        document.getElementById("tempCheckbox").checked = false;
        updateOptionsForTemprature();
    }
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode != 46 && (charCode < 48 || charCode > 57)))
        return false;
    return true;
}

function renderQuestion() {
    if (templates[questionIndex].type == 'temperature') {
        var questionContent = ''
        questionContent += '<div class="d-flex" style="align-self: flex-start; margin-top:auto; align-items: end">' + (parseInt(questionIndex) + 1) + '. ' + templates[questionIndex].label + ': <input id="temprature" onkeypress="return isNumberKey(event)" class="inputNumber" type="number" step="0.01" > F' + '</div>'
        questionContent += '<div class="note">(Note: Temperature should be between 95.00&#176;F to 107.00&#176;F)</div>';
        questionContent += '<div class="checkbox_label" onclick="updateCheckbox()"><input type="checkbox" id="tempCheckbox" onchange="updateOptionsForTemprature(event)"> <span>I do not own a thermometer</span></div>';
        document.getElementById("questionSpace").innerHTML = questionContent
        if (templates[questionIndex].selection.length > 0 && templates[questionIndex].selection[0].hide == false) {
            setTimeout(() => {
                document.getElementById("tempCheckbox").checked = true;
            }, 100);
        }

    } else {
        document.getElementById("questionSpace").innerHTML = '<div class="d-flex" style="align-self: flex-start;margin-top:auto">' + (parseInt(questionIndex) + 1) + '. ' + templates[questionIndex].label + '</div>'
    }

    if (questionIndex == 0) {
        document.getElementById("questionSpace").innerHTML = '<div class="" style="margin-top: auto;">Welcome to COVID-19 screening</div><br/>' + document.getElementById("questionSpace").innerHTML
    }

    if (document.getElementById("temprature")) {
        document.getElementById("temprature").addEventListener("keyup", event => {
            let inRange = event.target.value >= 95 && event.target.value < 107.01
            if (inRange) {
                changeButtonStatus();
            } else {
                document.getElementById("submit").classList.add("disabled");
            }
            let index = -1;
            for (let i = 0; i < templates[questionIndex].options.length && index == -1; i++) {
                if (templates[questionIndex].options[i].label.indexOf('Temperature') != -1) {
                    index = i
                }
            }
            templates[questionIndex].options[index].label = "Temperature:" + event.target.value;
            templates[questionIndex].options[index].value = event.target.value;
            selectOption(templates[questionIndex].options[index].id, !inRange)
        });
        // document.getElementById("temprature").addEventListener("keypress", event => {
        //     document.getElementById("tempCheckbox").checked = false;
        //     updateOptionsForTemprature();
        //     var num = event.target.value + event.key
        //     if (num.indexOf('.') == -1) {
        //         if (num.length > 2) {
        //             event.target.value = num.slice(0, 2)
        //         }
        //     } else {
        //         if (num.length > 5) {
        //             event.target.value = num.slice(0, 5)
        //         }
        //     }
        // });
    }
    if(jSForMobile){
        //not needed for web
        let elem = document.getElementById("optionSpace")
        document.getElementById("questionSpace").style.minHeight = "calc((110vh - 110px - (" + elem.clientHeight + "px + 50px))/2)";
    }
   
}

function renderOptions() {
    var optionSection = '';
    for (let i = 0; i < templates[questionIndex].options.length; i++) {
        if (!templates[questionIndex].options[i].noDisplay && !templates[questionIndex].options[i].hide) {
            optionSection += '<div class="button" id="' + templates[questionIndex].options[i].id + '" onclick=selectOption("' + templates[questionIndex].options[i].id + '")>' + templates[questionIndex].options[i].label + '</div>';
        }
    }
    elem = document.getElementById("optionSpace")
    elem.innerHTML = optionSection;
    if (templates[questionIndex].type == "singleSelect") {
        elem.classList.add('d-flex')
        elem.classList.remove('d-block')
    } else {
        elem.classList.add('d-block')
        elem.classList.remove('d-flex')
    }
    enableSelected();
    changeButtonStatus();
}

function setContent() {
    let addClass = '';
    if (templates[questionIndex].required) {
        addClass = "disabled";
    }
    if (questionIndex == templates.length - 1) {
        document.getElementById("actionSpace").innerHTML = '<div class="button actionButton ' + addClass + '" id="submit" onclick="submit()">Submit</div><div class="button actionButton" id="back" onclick="back()">Previous</div>'
    } else if (questionIndex == 0) {
        document.getElementById("actionSpace").innerHTML = '<div class="button actionButton ' + addClass + '" id="submit" onclick="Next()">Next</div>'
    } else {
        document.getElementById("actionSpace").innerHTML = '<div class="button actionButton ' + addClass + '" id="submit" onclick="Next()">Next</div><div class="button actionButton" id="back" onclick="back()">Previous</div>'
    }
    renderOptions();
    renderQuestion();
}

function submit() {
    emit();
}

function Next() {
    if (templates[questionIndex].selection.length > 0) {
        if (questionIndex == templates.length - 1) {
            submit()
        } else {
            questionIndex = questionIndex + 1;
            setContent();
        }
    }

    updateWebEvent('next');
   
}

function back() {
    if (questionIndex != 0) {
        questionIndex = questionIndex - 1;
        setContent();
        enableSelected();
        changeButtonStatus()
        updateWebEvent('previous')
    }
}

function enableSelected() {
    for (let i = 0; i < templates[questionIndex].selection.length; i++) {
        if (templates[questionIndex].selection[i].value) {
            setTimeout(() => {
                document.getElementById("temprature").value = templates[questionIndex].selection[i].value;
            }, 100);

        } else {
            enableButton(templates[questionIndex].selection[i].id);
        }
    }
}

function selectOption(id, overRideSubmit) {
    // option = id.replace(/_/gi, ' ');
    var optionIndex = templates[questionIndex].options.findIndex(x => x.id == id)
    var option = templates[questionIndex].options[optionIndex];
    if (templates[questionIndex].type == 'singleSelect' || templates[questionIndex].type == 'temperature') {
        if (templates[questionIndex].selection.length > 0) {
            disableButton(templates[questionIndex].selection[0].id);
        }
        templates[questionIndex].selection = [option];
        if (templates[questionIndex].selection.length > 0) {
            enableButton(templates[questionIndex].selection[0].id);
        }
        if (templates[questionIndex].required && !overRideSubmit) {
            document.getElementById("submit").classList.remove("disabled");
        }
    } else if (templates[questionIndex].type == 'multiSelect') {

        if (option.primarySelection && (templates[questionIndex].selection.length > 1 || (templates[questionIndex].selection.length == 1 && option.id != templates[questionIndex].selection[0].id))) {
            for (let i = 0; i < templates[questionIndex].selection.length; i++) {
                disableButton(templates[questionIndex].selection[i].id);
            }
            templates[questionIndex].selection = [];
        } else if (!option.primarySelection) {
            var noneOfTheAboveIndex = -1;
            for (let i = 0; i < templates[questionIndex].selection.length; i++) {
                if (templates[questionIndex].selection[i].primarySelection) noneOfTheAboveIndex = i;
            }
            if (noneOfTheAboveIndex != -1) {
                disableButton(templates[questionIndex].selection[noneOfTheAboveIndex].id);
                templates[questionIndex].selection.splice(noneOfTheAboveIndex, 1);
            }
        }
        var index = -1;
        for (let i = 0; i < templates[questionIndex].selection.length; i++) {
            if (templates[questionIndex].selection[i].id == option.id) index = i;
        }
        if (index == -1) {
            templates[questionIndex].selection.push(option);
            document.getElementById(option.id).classList.add('form-tag-active');
        } else {
            document.getElementById(option.id).classList.remove('form-tag-active');
            templates[questionIndex].selection.splice(index, 1);
        }
        if (templates[questionIndex].required) {
            changeButtonStatus();
        }
    }
}

function disableButton(id) {
    elem = document.getElementById(id);
    if (elem) elem.classList.remove('form-tag-active');
}

function enableButton(id) {
    elem = document.getElementById(id);
    if (elem) elem.classList.add('form-tag-active');
}

function changeButtonStatus() {
    if (templates[questionIndex].selection.length > 0) {
        document.getElementById("submit").classList.remove("disabled");
    } else {
        document.getElementById("submit").classList.add("disabled");
    }
}

function emit() {
    var formResponse = [];
    for (let i = 0; i < templates.length; i++) {
        var selection = [];
        for (let x = 0; x < templates[i].selection.length; x++) {
            selection.push(templates[i].selection[x].label)
        }
        formResponse.push({ "question": templates[i].label, "answer": selection })
    }
    response = { response: formResponse, "surveyId": surveyId, "shiftId": shiftId, metadata: {}, status: 0 }
    var validationResult = ValidateState()
    if (validationResult) {
        response.metadata["number"] = number
        response.metadata["action"] = "call"
        response.status = validationResult;
        if (jSForMobile) {
          response.metadata["message"] = "Please contact the HR Command Center at (561)-273-2178 prior to reporting to work."
          displayResult('Please contact the HR Command Center at <span class="link">(<a href="tel:(561)-273-2178">561)-273-2178</a></span> prior to reporting to work.')
        } else {
            //psoitive resp
            let type = document.getElementById('selectedType').innerText;
            let msg='';
            for(var i=0;i< respMsg.length;i++){
                if(type == respMsg[i].name){
                    msg = respMsg[i].positiveMsg
                }
            }
            response.metadata["message"] = msg;
            displayResult(msg)
        }
       
    } else {
        response.metadata["message"] = "Thank you. You are clear to work today.";
        response.status = validationResult;
        
        if (jSForMobile) {
            response.metadata["message"] = "Thank you. You are clear to work today.";
            displayResult("Thank you. You are clear to work today.")
        } else {
            let type = document.getElementById('selectedType').innerText;
            let msg = '';
            for (var i = 0; i < respMsg.length; i++) {
                if (type == respMsg[i].name) {
                    msg = respMsg[i].negativeMsg
                }
            }
            response.metadata["message"] = msg;
            displayResult(msg)
        }
    }
    // console.log(response)
    response = JSON.stringify(response);
    sendData();
}

function sendData() {
  
    if (jSForMobile) {
    JSReceiver.onResponse(response);
    }else{
        updateWebEvent('submit')
    }
}


function onClose() {
    if (jSForMobile) {
        JSReceiver.onResponse(response);
    } else {
        updateWebEvent('close');
        for(var i=0; i<templates.length;i++){
            templates[i].selection = [];
            for(var j = 0; j < templates[i].options.length; j++){
                if(templates[i].options[j].hide != undefined){
                    templates[i].options[j].hide = true;
                }
            }
        } 
        updateOptionsForTemprature();
        questionIndex = 0;
        surveyId = "";
        shiftId = ""
        response = {};
        number = "5612732178";
        document.getElementById("result").innerHTML = '';
        init();
        setContent()
    }
}

function displayResult(msg) {
    document.getElementById("questionSpace").innerHTML = '';
    document.getElementById("optionSpace").innerHTML = '';
    
    if(jSForMobile){
        //  only for mobile device
        document.getElementById("questionSpace").style.minHeight = "calc((110vh - 110px) /5)";
    }

    
    document.getElementById("actionSpace").innerHTML = '<div class="button actionButton form_close" id="submit" onclick="onClose()">Close</div>'
    document.getElementById("result").innerHTML = '<div>' + msg + '</div>';
}

function ValidateState() {
    var flag = false;
    for (let i = 0; i < templates.length; i++) {
        for (let x = 0; x < templates[i].selection.length; x++) {
            if (templates[i].selection[x].responseValue == 1) {
                flag = true
            }

            if (templates[i].selection[x].value != undefined && templates[i].selection[x].value >= 100) {
                flag = true;
            }
        }
        // if (templates[i].type == 'singleSelect') {
        //     if (templates[i].selection[0].label == "Yes") {
        //         flag = true;
        //     }
        // }
        // if (templates[i].type == 'multiSelect') {
        //     if (templates[i].selection[0].label != "None of the above") {
        //         flag = true;
        //     }
        // }
    }
    return flag
}


function updateWebEvent(type) {
    let event_elem = document.getElementById("surveyFormContainer");
    g_event.data = {
        submited: (type == 'submit'),
        state: type,
        responce: response,
        totalQuestion: templates.length,
        currentIndex: questionIndex
    }
    g_event.isTrusted = true;
    event_elem.dispatchEvent(g_event);
    
}

function skipSurvey(){
    // console.log('test')
    onClose();
    init();
}

function loadFormAngular(){
    try {
        questionIndex = 0;
        surveyId = "";
        shiftId = ""
        response = {};
        number = "5612732178";
        for (var i = 0; i < templates.length; i++) {
            templates[i].selection = [];
            for (var j = 0; j < templates[i].options.length; j++) {
                if (templates[i].options[j].hide != undefined) {
                    templates[i].options[j].hide = true;
                }
            }
        } 
        init();
    } catch (error) {
        // console.log(error);
    }
}

window.onload = (event) => {
   try{
       init();
   } catch(error){
    //    console.log(error);
   }
    
};
